.hero-simple {
  background: {
    image: url("/assets/bna-pattern.svg");
    position: 50% 0;
    size: 2500px;
  }
  @include tablet {
    .breadcrumbs {
      display: none;
    }
  }
  &__content {
    padding: {
      top: 185px;
      bottom: 36px;
    }
    @include mobile {
      padding-top: 120px;
    }
  }
  h1 {
    font-size: 36px;
    color: white;
    strong,
    span {
      font-weight: inherit;
      color: $color-yellow;
    }
    @include mobile {
      font-size: 28px;
    }
    & + em {
      @include font-roboto-mono-regular;
      font-size: 11px;
      text-transform: uppercase;
      color: $color-shady-lady-grey;
      letter-spacing: 0.4px;
      display: block;
      margin-top: 1em;
    }
  }
}
