.contact-banner {
  display: flex;
  width: 100%;
  margin-top: 50px;
  align-items: stretch;
  @include tablet {
    flex-direction: column;
  }
  &__left {
    background-color: $color-bna-yellow;
    flex-grow: 1;
    padding: 45px 37px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%;

    &:after {
      content: "";
      display: block;
      position: absolute;
      right: -13px;
      top: 47px;
      width: 0;
      height: 0;
      border-left: 13px solid $color-bna-yellow;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent;
    }
    @include tablet {
      padding: 20px 18px;
      &:after {
        left: 50%;
        right: auto;
        transform: translateX(-50%);
        top: auto;
        bottom: -13px;
        border-bottom: 0;
        border-top: 13px solid $color-bna-yellow;
        border-left: 18px solid transparent;
        border-right: 18px solid transparent;
      }
    }
    &-copy {
      margin-bottom: 30px;
    }

    h3 {
      font-size: 19px;
      margin-bottom: 0.5em;
    }
  }
  &__right {
    min-width: 310px;
    min-height: 300px;
    padding: 18px 28px;
    display: flex;
    align-items: flex-end;
    background: {
      size: cover;
      color: $color-shady-lady-grey;
    }
    @include mobile {
      min-width: 0;
    }
  }
  &__employee {
    color: white;
    font-size: 13px;
    strong {
      display: block;
    }
  }
}
