.news-single {
  &__img {
    display: block;
    width: 100%;
    max-height: 300px;
    overflow: hidden;
    margin-bottom: 20px;
    img {
      min-width: 100%;
    }
  }
  &__related-header {
    @include font-graphik-medium;
    font-size: 19px;
    color: black;
    margin-bottom: 12px;
  }
  .related-entry {
    display: flex;
    padding: 20px 28px 24px 28px;
    border-top: 1px solid #d4d4d4;
    &__date {
      @include date-format;
      font-size: 10px;
    }
    &__header {
      font-weight: 500;
      font-size: 15px;
      font-style: normal;
      line-height: 1.2;
      color: black;
    }
  }
}
