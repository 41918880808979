.share {
  border-top: 1px solid $color-silver;
  margin-top: 40px;
  padding-top: 40px;
  &__header {
    display: block;
    margin-bottom: 12px;
  }
  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-top: 6px;
  }
}
