.gallery-detail {
  box-sizing: border-box;
  position: relative;
  &__img {
    display: block;
    max-height: 70vh;
    max-width: 1024px;

    @include mobile {
      width: calc(100vw - 40px);
    }
  }

  &__previous,
  &__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: $color-bna-yellow;
    width: 60px;
    height: 60px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  &__previous {
    left: calc((100vw - 100%) / -2);
  }
  &__next {
    right: calc((100vw - 100%) / -2);
  }
  &__info {
    text-align: center;
    position: absolute;
    bottom: calc(((100vh - 100%) / -2) + 26px);
    width: 100%;
    color: white;
  }
  &__source {
    display: block;
    font-size: 14px;
    strong {
      color: $color-bna-yellow;
      font-weight: 400;
      text-decoration: underline;
    }
  }
  &__bullets {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    .gallery-detail__bullet {
      width: 18px;
      height: 3px;
      background-color: white;
      margin-right: 12px;
      cursor: pointer;

      &:last-child {
        margin: 0;
      }
      &.active {
        background-color: $color-bna-yellow;
      }
    }
  }
}
