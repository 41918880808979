.breadcrumbs {
  position: relative;
  z-index: 3;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 60px;
  padding: 16px 0;
}
.breadcrumb-list {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0;
  li {
    font-size: 13px;

    a {
      color: white;
      &:hover {
        text-decoration: underline;
      }
    }
    &:last-child {
      color: $color-bna-yellow;
      text-decoration: underline;
      a {
        color: $color-bna-yellow;
      }
    }
  }
}
.breadcrumb-arrow {
  display: block;
  margin: 0 10px;
}
