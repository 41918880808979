.main-header {
  position: fixed;
  z-index: 8888;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out;

  @include desktop {
    &.small {
      .main-header__wrapper {
        max-width: 100%;
        padding: 0;
        transition: 250ms ease-in-out;
      }
      .main-header__logo {
        height: auto;
        width: 150px;
        transition: 250ms ease-in-out;
        padding: {
          top: 8px;
          bottom: 8px;
        }
        img {
          height: 50px;
          & + span {
            display: none;
          }
        }
      }
    }
  }

  &.active {
    @include tablet {
      .main-header__items {
        transform: translateX(0);
        padding-top: 80px;
        padding-bottom: 60px;
      }
      .main-header__mobile-menu {
        color: white;
        .closed,
        .main-header__mobile-menu-icon {
          display: none;
        }
        .opened {
          display: block;
        }
      }
      .main-header__international {
        display: flex;
        position: absolute;
        z-index: 10;
        top: 26px;
        left: 0;
        background: transparent;
        color: white;
        border: 0;

        svg {
          fill: #ffffff;
        }
      }
    }
  }

  &__wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: stretch;
    transition: 250ms ease-in-out;

    @include tablet {
      max-width: 100%;
      padding: 0;
    }
  }

  &__logo {
    width: 200px;
    height: auto;
    padding: 14px;
    background-color: white;
    border: 1px solid #d4d4d4;
    transition: 250ms ease-in-out;
    display: flex;
    flex-direction: column;
    img {
      display: block;
      margin: 0 auto;
      height: 80px;
      transition: 250ms ease-in-out;
      & + span {
        text-align: center;
        font-size: 16px;
        line-height: 1em;
        padding: 10px 0 0;
        @include font-serifa-light;

        @include tablet {
          display: none;
        }
      }
    }
    @include tablet {
      width: 110px;
      height: auto;
      justify-content: center;
      padding: {
        top: 18px;
        bottom: 18px;
      }
      img {
        height: 36px;
      }
    }
  }
  &__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-left: 0;
  }
  &__nav-container {
    display: flex;
    min-height: 74px;
    background-color: $color-hint-of-red-grey;
    border: 1px solid #d4d4d4;
    border-left: 0;

    @include tablet {
      justify-content: space-between;
    }
  }
  &__search-container {
    height: 0;
    overflow: hidden;

    &.active {
      border: 1px solid #d4d4d4;
      border-top: 0;
      border-left: 0;
      height: auto;
      flex-grow: 1;
      background-color: white;
      height: 50px;
    }
    form {
      width: 100%;
      display: flex;
      align-items: stretch;
      height: 100%;
      input[type="search"] {
        -webkit-appearance: none;
        border: 0;
        flex-grow: 1;
        display: block;
      }
      button {
        display: none;
      }
    }
  }
  &__search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #d4d4d4;
    padding: 20px;
    cursor: pointer;
    img {
      width: 30px;
      display: block;
    }
  }
  &__items {
    width: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 12px;
    @include tablet {
      position: fixed;
      left: 0;
      bottom: 0;
      top: 0;
      right: 0;
      padding: 40px 26px;
      margin-left: 0;
      background-image: url(/assets/bna-pattern.svg);
      background-position: 0 100%;
      background-size: 400% auto;
      background-color: black;
      flex-direction: column;
      transform: translateX(-150%);
      align-items: flex-start;
    }
  }
  &__item {
    @include font-graphik-regular;
    font-size: 16px;
    padding: 0 12px 0 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    position: relative;
    white-space: nowrap;

    @include tablet {
      height: auto;
      padding: 20px 0;
      @include font-serifa-medium;
      font-size: 22px;
      display: block;
      color: white;
    }

    &--dropdown {
      border-left: 1px solid $color-hint-of-red-grey;
      border-right: 1px solid $color-hint-of-red-grey;
      &:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 8px;
        border-top: 5px solid black;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
      }
      @include tablet {
        border: 0;
        width: 100%;

        &:after {
          border-top: 5px solid white;
          display: none;
        }
      }
      &.dropdown-hidden {
        background-color: transparent;
        .main-header__item-dropdown {
          display: none;
        }
      }
      &:not(.dropdown-hidden) {
        background-color: white;
        border-color: #d4d4d4;

        &:before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          height: 4px;
          z-index: 5;
          bottom: -1px;
          background-color: white;
        }
        @include tablet {
          background: transparent;
          &:before {
            display: none;
          }
        }
      }
    }
  }
  &__item-dropdown {
    width: 296px;
    position: absolute;
    left: -1px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    padding: 24px;
    position: absolute;
    top: 72px;
    padding: 20px 30px;

    @include tablet {
      position: static;
      width: 100%;
      border: 0;
      color: white;
      background: transparent;
      padding: {
        left: 0;
        right: 0;
        bottom: 0;
      }

      a {
        color: white;
      }
    }

    a {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 1.2;
      padding: 16px 0;
      cursor: pointer;
      border-bottom: 1px solid #d4d4d4;

      &:last-child {
        border: 0;
      }
    }
  }
  &__international {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-left: 1px solid #d4d4d4;
    font-weight: 500;
    font-size: 15px;
    white-space: nowrap;
    padding: 0 20px;

    &-icon {
      width: 18px;
      height: 18px;
      margin-right: 12px;
    }

    @include tablet {
      display: none;
    }
  }
  &__mobile-menu {
    display: none;
    align-items: center;
    padding: 0 16px;
    position: relative;
    strong {
      margin-right: 8px;
    }
    .opened {
      display: none;
    }
    @include tablet {
      display: flex;
    }
  }
  &__mobile-menu-icon {
    background-color: $color-xdark-grey;
    padding: 6px;
    width: 32px;
    height: 32px;
    svg {
      width: 100%;
      height: 100%;
      fill: white;
    }
  }
}
