// BASE GRID LAYOUT STYLING
.grid-container {
  max-width: 1200px;
  margin: 40px auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;

  &.flex {
    display: flex;
  }

  &.sidebar-grid {
    justify-content: space-between;
    display: flex;
    .two-third {
      max-width: calc(68% - 32px);
      margin-right: 32px;
      width: 100%;
    }
    .one-third {
      width: calc(32% - 26px);
      min-width: 320px;
    }

    @include mobile {
      flex-direction: column;
      .one-third,
      .two-third {
        width: 100%;
        max-width: none;
      }
      .two-third {
        margin-right: 0;
      }
      .one-third {
        margin-top: 40px;
        min-width: 0;
      }
    }
  }

  &.no-padding {
    padding: {
      left: 0;
      right: 0;
    }
  }

  &.no-margin {
    margin: {
      top: 0;
      bottom: 0;
    }
  }
}

// DISTANCES, POSITIONS ETC.
