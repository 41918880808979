.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  &__container {
    width: 100%;
    max-width: 600px;
    padding: 60px 40px 40px;
    background-color: $color-white-grey;
    text-align: center;
    h1 {
      img {
        display: inline-block;
        width: 44px;
        height: 44px;
        margin-left: 20px;
      }
    }

    h2 {
      font-size: 30px;
      margin-top: 30px;
    }
    p {
      display: block;
      margin: 30px 0;
    }
  }
}
