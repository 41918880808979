.news-filter {
  &__categories {
    display: flex;
    flex-wrap: wrap;
  }

  &__categories-single {
    cursor: pointer;
    padding: 8px;
    color: $color-black;
    background: $color-white-smoke;
    border: 1px solid $color-white-smoke;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    @include font-roboto-mono-medium;
    font-size: 12px;
    font-weight: 400;
    padding: 3px 7px;
    letter-spacing: 1px;
    z-index: 1;
    margin-bottom: 10px;
    text-transform: uppercase;
    margin-right: 8px;

    &.active {
      color: $color-black;
      background: $color-yellow;
      font-weight: 500;
      border: 1px solid $color-yellow;
    }
  }
  &__articles {
    display: flex;
    flex-wrap: wrap;
    max-width: 900px;
    margin: 36px auto 0;
    margin-bottom: 142px;

    @include mobile {
      flex-direction: column;
      margin-bottom: 50px;
    }
  }
  .article-single {
    width: calc(50% - 18px);
    position: relative;
    display: block;
    margin-top: 36px;

    @include mobile {
      width: 100%;
    }

    &:nth-of-type(odd) {
      margin-right: 18px;
    }
    &:nth-of-type(even) {
      margin-left: 18px;
      top: 100px;
    }
    @include mobile {
      &:nth-of-type(1n) {
        top: auto;
        margin: 0 0 24px 0;
      }
    }
    &__category-label {
      position: absolute;
      left: 0;
      top: 0;
      display: flex;
      span {
        @include bna-label();
        padding: 4px 7px;
        z-index: 1;
        margin-bottom: 8px;
        &:not(:last-child) {
          margin-right: 8px;
        }
      }
    }
    &__img {
      width: 100%;
      height: 56vw;
      display: block;
      max-height: 512px;
      background-color: grey;
      background: {
        size: cover;
        position: 50% 50%;
      }
    }
    &__meta {
      padding: 34px 28px 0 28px;
      height: 150px;
      background-color: $color-concrete;
    }
    &__title {
      display: block;
      color: black;
      font-size: 19px;
      @include font-graphik-medium;
    }
    &__date {
      @include date-format;
    }
    &__no-results {
      width: 100%;
      display: block;
      text-align: center;
      padding: 80px 0 40px;
    }
  }
  &__loadmore-container {
    max-width: 900px;
    margin: 0 auto;
  }
  &__loadmore {
    display: flex;
    max-width: 260px;
    height: 64px;
    background-color: #373a43;
    color: white;
    padding: 20px;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    &-spinner {
      width: 30px;
      height: 30px;
      margin-left: 20px;
    }
  }
  &__load-widget {
    position: relative;
    min-height: 300px;
    .news-filter__load-widget-spinner {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
