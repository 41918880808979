.hero {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: grey;
  overflow: hidden;

  @include tablet {
    .breadcrumbs {
      display: none;
    }
  }
  @include mobile {
    height: auto;
    min-height: 300px;
  }
  &--shorten {
    height: auto;
    min-height: 600px;

    @include mobile {
      min-height: 300px;
    }
  }

  &.no-breadcrumbs {
    .hero__photo-description {
      bottom: 8px;
    }
  }

  &__image {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: -25px;
    background: {
      size: cover;
      position: 50% 0%;
    }
    backface-visibility: hidden;

    &:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(
        to bottom,
        rgba(0, 0, 0, 0.25) 0%,
        rgba(0, 0, 0, 0.25) 1%,
        rgba(0, 0, 0, 0.25) 81%,
        rgba(0, 0, 0, 0.5) 100%
      );
    }
  }
  &__architect {
    position: absolute;
    background-color: white;
    letter-spacing: 0.5px;
    color: black;
    @include font-roboto-mono-regular;
    font-weight: 600;
    right: 20px;
    top: 50px;
    font-size: 11px;
    text-transform: uppercase;
    padding: 3px 10px;

    @include mobile {
      top: 36px;
    }
  }
  &__photo-description {
    position: absolute;
    right: 20px;
    bottom: -30px;
    color: white;
    z-index: 10;
    font-size: 14px;
    font-style: italic;
    @include tablet {
      display: none;
    }
  }
  &__content {
    color: white;
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 125px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include mobile {
      padding: {
        top: 36px;
        bottom: 36px;
      }
      margin-bottom: 0;
    }

    h1 {
      max-width: 620px;
      margin-top: 0.7em;
      color: white;
      @include mobile {
        margin-top: 0.4em;
      }
    }
    &-body {
      margin-top: 20px;
      margin-bottom: 12px;

      @include mobile {
        display: none;
      }
    }

    h2 {
      @include font-roboto-mono-regular;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      width: 100%;
      color: white;

      &:before {
        position: relative;
        top: -1px;
        content: "";
        display: block;
        flex: 0 0 32px;
        height: 4px;
        background-color: $color-bna-yellow;
        margin-right: 12px;
      }
    }
    &-cta {
      display: flex;
      color: white;
      font-size: 18px;
      margin-top: 30px;
      img {
        width: 50px;
        margin-right: 12px;
      }
    }
  }

  &__tabs {
    position: absolute;
    display: flex;
    color: black;
    right: calc((100% - 1140px) / 2);
    bottom: 0;
    z-index: 3;
    // specific media query to maintain relative position
    @media all and (max-width: 1200px) {
      right: 30px;
    }

    @include mobile {
      position: relative;
      right: auto;
      top: auto;
      flex-direction: column;
      width: 100%;
      padding: 0 20px;
    }

    &-single {
      text-align: center;
      min-width: 180px;
      background-color: $color-bna-yellow;
      box-shadow: inset 0 -5px 8px -6px black;
      padding: 10px 20px 8px;
      font-weight: 500;
      width: 120px;
      margin-left: 20px;
      cursor: pointer;

      &.active {
        background-color: white;
        box-shadow: none;
        cursor: default;
      }
      @include mobile {
        width: 100%;
        margin-left: 0;
        margin-bottom: 12px;
        padding: {
          top: 15px;
          bottom: 15px;
        }
        box-shadow: none;
      }
    }
  }
  &__project-nav {
    position: absolute;
    bottom: 0;
    z-index: 20;
    background-color: white;
    height: 60px;
    font-size: 13px;
    padding: 6px 12px;
    line-height: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 500;

    @include mobile {
      background-color: transparent;
      color: white;

      svg {
        fill: white;
      }
    }

    svg {
      width: 23px;
    }
    &--prev {
      left: 0;
      align-items: flex-start;
    }
    &--next {
      right: 0;
      text-align: right;
    }
  }
}
// the content belongs to the hero tabs, so even though its not positioned in the hero, we still declare the styling here
[data-hero-tab-content|="2"] {
  display: none;
}
