.article-single {
  &__list {
    ul {
      list-style: none;
      padding: 0;

      li {
        margin-bottom: 6px;
        font-size: 14px;
        &.active {
          a {
            background-color: black;
            color: white;
            h2 {
              color: white;
            }
          }
          .article-single__mobile-copy {
            display: block;
          }
        }
        a {
          padding: 20px;
          font-weight: 500;
          background-color: $color-white-smoke;
          display: block;
          h2 {
            font-size: inherit;
            font-family: inherit;
          }
        }
      }
    }
  }
  &__mobile-copy {
    display: none;
  }
  &__intro {
    font-family: inherit;
    font-size: 19px;
  }
  &__body-copy {
    padding: 12px;
  }
  &__mobile-view {
    .article-single__list-item {
      a {
        h2 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          &:after {
            content: "";
            width: 0;
            height: 0;
            display: block;
            border-top: 6px solid black;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
          }
        }
      }
      &.active {
        a {
          h2 {
            &:after {
              border-top: 0;
              border-bottom: 6px solid white;
            }
          }
        }
      }
    }
  }
}
