.app-page {
  &.homepage {
    background: {
      image: url("/assets/bna-pattern.svg");
      position: 0 100%;
      size: 170% auto;
      color: black;
    }
    .main-footer {
      background: none;
    }
  }
}
