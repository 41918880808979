.breadcrumbs-mobile {
  position: relative;
  z-index: 3;
  border-top: 1px solid $color-silver;
  border-bottom: 1px solid $color-silver;
  width: 100%;
  padding: 16px 0;
  display: none;
  margin-top: 30px;

  @include tablet {
    display: block;
  }
  &__list {
    list-style: none;
    align-items: center;
    padding: 0;
    li {
      font-size: 15px;
      line-height: 2.08;
      display: inline-block;

      a {
        &:hover {
          text-decoration: underline;
        }
      }
      &:last-child {
        color: $color-xdark-turquoise;
        text-decoration: underline;
        a {
          color: $color-xdark-turquoise;
        }
      }
    }
  }
  &__arrow {
    margin: 0 10px;
    display: inline-block;
  }
}
