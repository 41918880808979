.video-bar {
  padding: 12px 12px 12px 30px;
  background-color: #fff402;
  max-width: 1160px;
  margin: 0 auto;
  line-height: 1;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -36px;
  position: relative;
  z-index: 20;

  @include tablet {
    margin-top: 0;
  }

  @include mobile {
    flex-direction: column;
    padding: 12px;
  }

  h2 {
    margin-right: 12px;
    font-size: 22px;
    @include mobile {
      margin: 0;
      text-align: center;
    }
  }

  &__video {
    min-width: 120px;
    width: 120px;
    height: 66px;
    background-color: black;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    video {
      width: 100%;
    }
    @include mobile {
      margin-top: 12px;
      height: auto;
      width: 100%;
    }
  }
  &__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    transform: translate(-50%, -50%);
  }
}
