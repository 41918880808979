@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Regular.woff2") format("woff2"),
    url("/fonts/Graphik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-RegularItalic.woff2") format("woff2"),
    url("/fonts/Graphik-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Medium.woff2") format("woff2"),
    url("/fonts/Graphik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Bold.woff2") format("woff2"),
    url("/fonts/Graphik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

$fw-thin: 100;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-extra-bold: 800;
$fw-black: 900;

$ff-graphik: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
$ff-serifa: "Serifa", Times, "Times New Roman", serif;
$ff-roboto-mono: "Roboto Mono", Monaco, "Courier New", Courier, monospace;

@mixin font-graphik-regular {
  font-family: $ff-graphik;
  font-weight: $fw-regular;
  font-style: normal;
}

@mixin font-graphik-regular-italic {
  font-family: $ff-graphik;
  font-weight: $fw-regular;
  font-style: italic;
}

@mixin font-graphik-medium {
  font-family: $ff-graphik;
  font-weight: $fw-medium;
  font-style: normal;
}

@mixin font-graphik-bold {
  font-family: $ff-graphik;
  font-weight: $fw-bold;
  font-style: normal;
}

@mixin font-serifa-medium {
  font-family: $ff-serifa;
  font-weight: $fw-medium;
  font-style: normal;
}
@mixin font-serifa-light {
  font-family: $ff-serifa;
  font-weight: $fw-thin;
  font-style: normal;
}

@mixin font-roboto-mono-regular {
  font-family: $ff-roboto-mono;
  font-weight: $fw-regular;
  font-style: normal;
  letter-spacing: 1px;
}

@mixin font-roboto-mono-medium {
  font-family: $ff-roboto-mono;
  font-weight: $fw-medium;
  font-style: normal;
  letter-spacing: 1px;
}

@mixin font-roboto-mono-bold {
  font-family: $ff-roboto-mono;
  font-weight: $fw-bold;
  font-style: normal;
  letter-spacing: 1px;
}
