.country-tracker {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 74px;
  background-color: $color-bna-yellow;
  z-index: 100;
  transition: 300ms ease-in-out transform;
  box-shadow: 0 -14px 34px 0 rgba(0, 0, 0, 0.3);

  @include tablet {
    height: auto;
    padding: 20px;
  }

  &.closed {
    transition: 250ms ease-in-out transform;
    transform: translateY(200%);
  }

  &__container {
    height: 100%;
    align-items: center;
    justify-content: space-between;
    @include tablet {
      padding-left: 0;
      padding-right: 30px;
    }
  }
  &__body {
    strong {
      font-weight: 500;
    }
    a {
      color: $color-xdark-turquoise;
      text-decoration: underline;
      margin-left: 10px;
      @include tablet {
        display: block;
        margin: 0;
      }
    }
  }
  &__close {
    font-weight: 600;
    font-size: 26px;
    padding: 0 12px;
    cursor: pointer;
    @include tablet {
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: 32px;
    }
  }
  &__ornament {
    position: relative;
    top: -18px;
    @include tablet {
      display: none;
    }
  }
}
