.custom-input-block {
  &__wrapper {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 2;
    padding: {
      top: 60px;
      bottom: 0;
    }
    margin: {
      top: 0;
      bottom: 0;
    }
    &:before {
      content: "";
      display: block;
      background-color: white;
      position: absolute;
      right: 15%;
      top: 0;
      left: calc(((100vw - 100%) / -2));
      bottom: 0;
      z-index: -1;
    }
    @include mobile {
      flex-direction: column;
      background-color: white;
      padding-top: 0;

      &:before {
        display: none;
      }
    }
  }
  &__left {
    width: 45%;
    padding: {
      right: 30px;
      top: 60px;
      bottom: 80px;
    }
    @include mobile {
      width: 100%;
      padding: {
        top: 0;
      }
    }
  }
  &__right {
    width: 55%;
    background: {
      size: cover;
    }
    @include mobile {
      display: none;
    }
  }
  &__subheader {
    display: flex;
    align-items: center;
    @include font-roboto-mono-medium;
    text-transform: uppercase;
    font-size: 12px;
    margin-bottom: 36px;
    margin-top: 30px;
    letter-spacing: 1.2px;
    @include bna-prefix;
  }
  &__header {
    font-style: normal;
    font-size: 38px;
    line-height: 1;
    margin-bottom: 30px;
    @include mobile {
      font-size: 28px;
    }
  }
  &__body {
    margin-bottom: 20px;
  }
}
