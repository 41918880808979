.contact-banner-small {
  background-color: $color-bna-yellow;
  padding: 20px;
  margin-bottom: 30px;

  h3 {
    border-bottom: 1px solid black;
    @include font-graphik-regular;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 22px;
  }
  &__agency,
  &__employee {
    font-size: 13px;
    color: black;
  }

  strong {
    display: block;
    font-weight: 500;
    line-height: 1.1em;
    letter-spacing: -0.2px;
  }
  .btn {
    margin-top: 24px;
  }
}
