.search {
  background-color: $color-white-grey;
  &__container {
    padding: 60px 40px;
    background-color: white;

    @include mobile {
      padding: 40px 20px;
    }
  }
  &__input {
    display: flex;
    width: 100%;
    input[type="search"] {
      -webkit-appearance: none;
      border: 1px solid $color-light-grey;
      height: 50px;
      flex-grow: 1;
    }
    button {
      background: none;
      padding: 0;
      border: 1px solid $color-light-grey;
      display: block;
      width: 50px;
      height: 50px;
      padding: 10px;
      border-left: none;
      margin: 0;
      cursor: pointer;
    }
    .search__icon {
      width: 100%;
      height: 100%;
    }
  }
  &__result-count {
    font-size: 19px;
    font-weight: 500;
    line-height: 1.21;
    color: black;
    padding: 30px 0;
    display: block;
  }
  &__entries {
    border-top: 1px solid grey;
    padding-top: 30px;

    .search-article {
      display: flex;
      align-items: center;
      margin-top: 20px;
      border: {
        top: 1px solid $color-light-grey;
        bottom: 1px solid $color-light-grey;
      }

      &__img {
        position: relative;
        width: 156px;
        height: 96px;
        background-color: $color-light-grey;
        overflow: hidden;
        @include mobile {
          width: 100px;
          min-width: 100px;
        }

        img {
          position: absolute;
          min-width: 100%;
          height: 100%;
          z-index: 1;
        }
      }
      &__category {
        @include bna-label;
        position: absolute;
        left: 0;
        top: 0;
        padding: 6px;
        z-index: 2;
        @include mobile {
          font-size: 9px;
        }
      }
      &__meta {
        padding: 20px;
      }
      &__header {
        @include font-graphik-regular;
        font-size: 15px;
        font-weight: 500;
        display: block;
      }
      &__date {
        @include bna-prefix;
        @include font-roboto-mono-medium;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 9px;
        margin-top: 6px;
      }
    }
  }
}
