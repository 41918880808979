.project-single {
  &__body {
    width: calc(32% - 26px);
  }
  &__specifications {
    background-color: $color-white-smoke;
    padding: 20px;
    margin-bottom: 30px;

    #toggle_specification_list {
      display: none;
      transition: 300ms ease-in-out;
    }

    &.type-collapse {
      position: relative;
      overflow: hidden;
      transition: 300ms ease-in-out;
      padding-bottom: 40px;

      #toggle_specification_list {
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 90px;
        background: red;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 60px 20px 14px 20px;
        font-size: 13px;
        background: linear-gradient(
          to bottom,
          rgba(246, 246, 246, 0) 0%,
          rgba(246, 246, 246, 1) 50%,
          rgba(246, 246, 246, 1) 100%
        );
        &:before {
          content: "";
          left: 0;
          right: 0;
          background: $color-silver;
          height: 1px;
          position: absolute;
          top: 42px;
        }
        &:after {
          content: "";
          display: block;
          width: 8px;
          height: 8px;
          border: 2px solid $color-silver;
          border-left: 0;
          border-bottom: 0;
          transform: translateY(-5px) rotate(135deg);
        }
      }
      &.collapsed {
        #toggle_specification_list {
          &:after {
            transform: translateY(0) rotate(-45deg);
          }
        }
      }
    }

    h3 {
      border-bottom: 1px solid $color-silver;
      @include font-graphik-regular;
      font-weight: 500;
      font-size: 18px;
      padding-bottom: 10px;
      margin-bottom: 22px;
    }
    strong,
    span {
      font-size: 13px;
    }
    strong {
      display: block;
      font-weight: 500;
      line-height: 1.1em;
      letter-spacing: -0.2px;
    }
    ul {
      list-style: none;
      padding: 0;

      li {
        margin-top: 19px;
        letter-spacing: -0.2px;
      }
    }
  }
  &__credits {
    border-bottom: 1px solid $color-silver;
    margin-bottom: 32px;
    strong,
    span {
      font-size: 13px;
    }
    strong {
      display: block;
      font-weight: 500;
      line-height: 1.1em;
      letter-spacing: -0.2px;
    }
    &-entry {
      margin-bottom: 20px;
    }
  }
}
