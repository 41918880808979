


































.lazy-img {
  &__paralax {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: {
      size: cover;
      position: 50% 50%;
    }
  }
}
