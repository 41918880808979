.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;

  &__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 5;
  }
  &__close {
    position: absolute;
    top: 40px;
    font-size: 50px;
    line-height: 22px;
    right: 50px;
    z-index: 10;
    text-align: center;
    padding: 6px 6px 6px 6px;
    color: white;
    cursor: pointer;

    @include mobile {
      right: 26px;
    }
  }
  &__view {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    min-width: 200px;
    min-height: 120px;
    background-color: white;
    z-index: 10;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: animate-modal 0.4s forwards;
    animation-delay: 0.3s;

    @keyframes animate-modal {
      0% {
        transform: translate(-50%, -60%);
        opacity: 0;
      }
      100% {
        transform: translate(-50%, -50%);
        opacity: 1;
      }
    }
  }
  &__static {
    padding: 40px 32px 30px 32px;
    p {
      text-align: center;
      color: black;
    }
  }
}
