.usps {
  background-color: $color-white-smoke;
  position: relative;
  @include mobile {
    display: none;
  }
  &.top-space {
    &:before {
      content: "";
      position: absolute;
      display: block;
      height: 80px;
      bottom: 100%;
      left: 0;
      right: 0;
      background-color: $color-white-smoke;
    }
  }
  &__entries {
    padding: {
      top: 23px;
      bottom: 23px;
    }
    margin: 0 auto;
    display: flex;
    list-style: none;
    justify-content: space-between;

    @include mobile {
      flex-direction: column;
      justify-content: initial;
      align-items: flex-start;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      @include mobile {
        padding-bottom: 12px;
      }
    }
  }
  &__icon {
    width: 40px;
    height: 40px;
    margin-right: 16px;
  }
}
