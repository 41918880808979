@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Regular.woff2") format("woff2"), url("/fonts/Graphik-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-RegularItalic.woff2") format("woff2"), url("/fonts/Graphik-RegularItalic.woff") format("woff");
  font-weight: normal;
  font-style: italic; }

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Medium.woff2") format("woff2"), url("/fonts/Graphik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: "Graphik";
  src: url("/fonts/Graphik-Bold.woff2") format("woff2"), url("/fonts/Graphik-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal; }

.grid-container {
  max-width: 1200px;
  margin: 40px auto;
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px; }
  .grid-container.flex {
    display: flex; }
  .grid-container.sidebar-grid {
    justify-content: space-between;
    display: flex; }
    .grid-container.sidebar-grid .two-third {
      max-width: calc(68% - 32px);
      margin-right: 32px;
      width: 100%; }
    .grid-container.sidebar-grid .one-third {
      width: calc(32% - 26px);
      min-width: 320px; }
    @media only screen and (max-width: 768px) {
      .grid-container.sidebar-grid {
        flex-direction: column; }
        .grid-container.sidebar-grid .one-third,
        .grid-container.sidebar-grid .two-third {
          width: 100%;
          max-width: none; }
        .grid-container.sidebar-grid .two-third {
          margin-right: 0; }
        .grid-container.sidebar-grid .one-third {
          margin-top: 40px;
          min-width: 0; } }
  .grid-container.no-padding {
    padding-left: 0;
    padding-right: 0; }
  .grid-container.no-margin {
    margin-top: 0;
    margin-bottom: 0; }

.btn {
  display: inline-flex;
  color: white;
  background-color: black;
  padding: 22px 30px;
  font-size: 16px;
  font-weight: 500; }
  .btn.stretch {
    width: 100%; }
    .btn.stretch span {
      text-align: center;
      flex-grow: 1;
      font-size: inherit; }
  .btn--download {
    background: transparent;
    color: black;
    padding-left: 0; }
  .btn__arrow-bottom {
    height: 40px;
    width: 46px;
    margin-right: 14px; }
  .btn__arrow-right {
    margin-left: 12px;
    height: 20px;
    width: 26px;
    transition: 300ms ease-in-out; }
  .btn:hover .btn__arrow-right {
    transform: translateX(6px);
    transition: 300ms ease-in-out; }

.thumbnail-blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px; }
  .thumbnail-blocks.cap .thumbnail-blocks__entry:nth-of-type(1n + 7) {
    display: none; }
  .thumbnail-blocks__show-more {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid grey;
    margin-top: 30px; }
  .thumbnail-blocks__entry {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
    margin-bottom: 30px; }
    .thumbnail-blocks__entry:hover .thumbnail-blocks__img-el {
      transform: scale(1.05);
      transition: 250ms ease-in-out; }
    .thumbnail-blocks__entry:nth-of-type(3n + 1) {
      margin-right: 20px; }
    .thumbnail-blocks__entry:nth-of-type(3n + 2) {
      margin-left: 10px;
      margin-right: 10px; }
    .thumbnail-blocks__entry:nth-of-type(3n + 3) {
      margin-left: 20px; }
    @media only screen and (max-width: 768px) {
      .thumbnail-blocks__entry {
        width: 100%; }
        .thumbnail-blocks__entry:nth-of-type(1n) {
          margin-left: 0;
          margin-right: 0; } }
    .thumbnail-blocks__entry h3 {
      font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      color: black; }
    .thumbnail-blocks__entry p {
      font-size: 13px; }
      .thumbnail-blocks__entry p strong {
        font-weight: 500; }
  .thumbnail-blocks__label {
    font-size: 11px;
    font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    line-height: 1.1;
    letter-spacing: 1px;
    color: #000;
    background: #fff402;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px 12px;
    z-index: 3; }
  .thumbnail-blocks__img {
    height: 22vw;
    max-height: 260px;
    background: #f6f6f6;
    width: 100%;
    position: relative;
    overflow: hidden; }
    @media only screen and (max-width: 768px) {
      .thumbnail-blocks__img {
        height: 50vw;
        max-height: 360px; } }
    .thumbnail-blocks__img-el {
      transition: 250ms ease-in-out;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background-size: cover; }
  .thumbnail-blocks__description {
    flex-grow: 1;
    width: 100%;
    background-color: #edf5f7;
    padding: 22px 20px; }

.article-single__list ul {
  list-style: none;
  padding: 0; }
  .article-single__list ul li {
    margin-bottom: 6px;
    font-size: 14px; }
    .article-single__list ul li.active a {
      background-color: black;
      color: white; }
      .article-single__list ul li.active a h2 {
        color: white; }
    .article-single__list ul li.active .article-single__mobile-copy {
      display: block; }
    .article-single__list ul li a {
      padding: 20px;
      font-weight: 500;
      background-color: #f6f6f6;
      display: block; }
      .article-single__list ul li a h2 {
        font-size: inherit;
        font-family: inherit; }

.article-single__mobile-copy {
  display: none; }

.article-single__intro {
  font-family: inherit;
  font-size: 19px; }

.article-single__body-copy {
  padding: 12px; }

.article-single__mobile-view .article-single__list-item a h2 {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .article-single__mobile-view .article-single__list-item a h2:after {
    content: "";
    width: 0;
    height: 0;
    display: block;
    border-top: 6px solid black;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent; }

.article-single__mobile-view .article-single__list-item.active a h2:after {
  border-top: 0;
  border-bottom: 6px solid white; }

.search {
  background-color: #f1f1f1; }
  .search__container {
    padding: 60px 40px;
    background-color: white; }
    @media only screen and (max-width: 768px) {
      .search__container {
        padding: 40px 20px; } }
  .search__input {
    display: flex;
    width: 100%; }
    .search__input input[type="search"] {
      -webkit-appearance: none;
      border: 1px solid #d4d4d4;
      height: 50px;
      flex-grow: 1; }
    .search__input button {
      background: none;
      padding: 0;
      border: 1px solid #d4d4d4;
      display: block;
      width: 50px;
      height: 50px;
      padding: 10px;
      border-left: none;
      margin: 0;
      cursor: pointer; }
    .search__input .search__icon {
      width: 100%;
      height: 100%; }
  .search__result-count {
    font-size: 19px;
    font-weight: 500;
    line-height: 1.21;
    color: black;
    padding: 30px 0;
    display: block; }
  .search__entries {
    border-top: 1px solid grey;
    padding-top: 30px; }
    .search__entries .search-article {
      display: flex;
      align-items: center;
      margin-top: 20px;
      border-top: 1px solid #d4d4d4;
      border-bottom: 1px solid #d4d4d4; }
      .search__entries .search-article__img {
        position: relative;
        width: 156px;
        height: 96px;
        background-color: #d4d4d4;
        overflow: hidden; }
        @media only screen and (max-width: 768px) {
          .search__entries .search-article__img {
            width: 100px;
            min-width: 100px; } }
        .search__entries .search-article__img img {
          position: absolute;
          min-width: 100%;
          height: 100%;
          z-index: 1; }
      .search__entries .search-article__category {
        font-size: 11px;
        font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
        font-weight: 400;
        font-style: normal;
        letter-spacing: 1px;
        line-height: 1.1;
        letter-spacing: 1px;
        color: #000;
        background: #fff402;
        text-transform: uppercase;
        position: absolute;
        left: 0;
        top: 0;
        padding: 6px;
        z-index: 2; }
        @media only screen and (max-width: 768px) {
          .search__entries .search-article__category {
            font-size: 9px; } }
      .search__entries .search-article__meta {
        padding: 20px; }
      .search__entries .search-article__header {
        font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 15px;
        font-weight: 500;
        display: block; }
      .search__entries .search-article__date {
        font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
        font-weight: 500;
        font-style: normal;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-size: 9px;
        margin-top: 6px; }
        .search__entries .search-article__date:before {
          content: "";
          display: block;
          left: 0;
          width: 36px;
          height: 4px;
          background-color: #fff402;
          margin-right: 12px; }

.page-404 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh; }
  .page-404__container {
    width: 100%;
    max-width: 600px;
    padding: 60px 40px 40px;
    background-color: #f1f1f1;
    text-align: center; }
    .page-404__container h1 img {
      display: inline-block;
      width: 44px;
      height: 44px;
      margin-left: 20px; }
    .page-404__container h2 {
      font-size: 30px;
      margin-top: 30px; }
    .page-404__container p {
      display: block;
      margin: 30px 0; }

.news-single__img {
  display: block;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  margin-bottom: 20px; }
  .news-single__img img {
    min-width: 100%; }

.news-single__related-header {
  font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 19px;
  color: black;
  margin-bottom: 12px; }

.news-single .related-entry {
  display: flex;
  padding: 20px 28px 24px 28px;
  border-top: 1px solid #d4d4d4; }
  .news-single .related-entry__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    line-height: 1.1;
    letter-spacing: 0.5px;
    margin-top: 1.5em;
    text-transform: uppercase;
    font-size: 10px; }
    .news-single .related-entry__date:before {
      content: "";
      position: relative;
      display: block;
      width: 30px;
      height: 6px;
      margin-right: 10px;
      background-color: #fff200; }
  .news-single .related-entry__header {
    font-weight: 500;
    font-size: 15px;
    font-style: normal;
    line-height: 1.2;
    color: black; }

.main-header {
  position: fixed;
  z-index: 8888;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  transition: all 0.2s ease-in-out; }
  @media only screen and (min-width: 992px) {
    .main-header.small .main-header__wrapper {
      max-width: 100%;
      padding: 0;
      transition: 250ms ease-in-out; }
    .main-header.small .main-header__logo {
      height: auto;
      width: 150px;
      transition: 250ms ease-in-out;
      padding-top: 8px;
      padding-bottom: 8px; }
      .main-header.small .main-header__logo img {
        height: 50px; }
        .main-header.small .main-header__logo img + span {
          display: none; } }
  @media only screen and (max-width: 992px) {
    .main-header.active .main-header__items {
      transform: translateX(0);
      padding-top: 80px;
      padding-bottom: 60px; }
    .main-header.active .main-header__mobile-menu {
      color: white; }
      .main-header.active .main-header__mobile-menu .closed,
      .main-header.active .main-header__mobile-menu .main-header__mobile-menu-icon {
        display: none; }
      .main-header.active .main-header__mobile-menu .opened {
        display: block; }
    .main-header.active .main-header__international {
      display: flex;
      position: absolute;
      z-index: 10;
      top: 26px;
      left: 0;
      background: transparent;
      color: white;
      border: 0; }
      .main-header.active .main-header__international svg {
        fill: #ffffff; } }
  .main-header__wrapper {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
    display: flex;
    align-items: stretch;
    transition: 250ms ease-in-out; }
    @media only screen and (max-width: 992px) {
      .main-header__wrapper {
        max-width: 100%;
        padding: 0; } }
  .main-header__logo {
    width: 200px;
    height: auto;
    padding: 14px;
    background-color: white;
    border: 1px solid #d4d4d4;
    transition: 250ms ease-in-out;
    display: flex;
    flex-direction: column; }
    .main-header__logo img {
      display: block;
      margin: 0 auto;
      height: 80px;
      transition: 250ms ease-in-out; }
      .main-header__logo img + span {
        text-align: center;
        font-size: 16px;
        line-height: 1em;
        padding: 10px 0 0;
        font-family: "Serifa", Times, "Times New Roman", serif;
        font-weight: 100;
        font-style: normal; }
        @media only screen and (max-width: 992px) {
          .main-header__logo img + span {
            display: none; } }
    @media only screen and (max-width: 992px) {
      .main-header__logo {
        width: 110px;
        height: auto;
        justify-content: center;
        padding-top: 18px;
        padding-bottom: 18px; }
        .main-header__logo img {
          height: 36px; } }
  .main-header__container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    border-left: 0; }
  .main-header__nav-container {
    display: flex;
    min-height: 74px;
    background-color: #fdfbfb;
    border: 1px solid #d4d4d4;
    border-left: 0; }
    @media only screen and (max-width: 992px) {
      .main-header__nav-container {
        justify-content: space-between; } }
  .main-header__search-container {
    height: 0;
    overflow: hidden; }
    .main-header__search-container.active {
      border: 1px solid #d4d4d4;
      border-top: 0;
      border-left: 0;
      height: auto;
      flex-grow: 1;
      background-color: white;
      height: 50px; }
    .main-header__search-container form {
      width: 100%;
      display: flex;
      align-items: stretch;
      height: 100%; }
      .main-header__search-container form input[type="search"] {
        -webkit-appearance: none;
        border: 0;
        flex-grow: 1;
        display: block; }
      .main-header__search-container form button {
        display: none; }
  .main-header__search-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-right: 1px solid #d4d4d4;
    padding: 20px;
    cursor: pointer; }
    .main-header__search-icon img {
      width: 30px;
      display: block; }
  .main-header__items {
    width: 100%;
    display: flex;
    align-items: center;
    flex-grow: 1;
    margin-left: 12px; }
    @media only screen and (max-width: 992px) {
      .main-header__items {
        position: fixed;
        left: 0;
        bottom: 0;
        top: 0;
        right: 0;
        padding: 40px 26px;
        margin-left: 0;
        background-image: url(/assets/bna-pattern.svg);
        background-position: 0 100%;
        background-size: 400% auto;
        background-color: black;
        flex-direction: column;
        transform: translateX(-150%);
        align-items: flex-start; } }
  .main-header__item {
    font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    padding: 0 12px 0 14px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 100%;
    position: relative;
    white-space: nowrap; }
    @media only screen and (max-width: 992px) {
      .main-header__item {
        height: auto;
        padding: 20px 0;
        font-family: "Serifa", Times, "Times New Roman", serif;
        font-weight: 500;
        font-style: normal;
        font-size: 22px;
        display: block;
        color: white; } }
    .main-header__item--dropdown {
      border-left: 1px solid #fdfbfb;
      border-right: 1px solid #fdfbfb; }
      .main-header__item--dropdown:after {
        content: "";
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 8px;
        border-top: 5px solid black;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent; }
      @media only screen and (max-width: 992px) {
        .main-header__item--dropdown {
          border: 0;
          width: 100%; }
          .main-header__item--dropdown:after {
            border-top: 5px solid white;
            display: none; } }
      .main-header__item--dropdown.dropdown-hidden {
        background-color: transparent; }
        .main-header__item--dropdown.dropdown-hidden .main-header__item-dropdown {
          display: none; }
      .main-header__item--dropdown:not(.dropdown-hidden) {
        background-color: white;
        border-color: #d4d4d4; }
        .main-header__item--dropdown:not(.dropdown-hidden):before {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          height: 4px;
          z-index: 5;
          bottom: -1px;
          background-color: white; }
        @media only screen and (max-width: 992px) {
          .main-header__item--dropdown:not(.dropdown-hidden) {
            background: transparent; }
            .main-header__item--dropdown:not(.dropdown-hidden):before {
              display: none; } }
  .main-header__item-dropdown {
    width: 296px;
    position: absolute;
    left: -1px;
    background-color: #fff;
    border: 1px solid #d4d4d4;
    padding: 24px;
    position: absolute;
    top: 72px;
    padding: 20px 30px; }
    @media only screen and (max-width: 992px) {
      .main-header__item-dropdown {
        position: static;
        width: 100%;
        border: 0;
        color: white;
        background: transparent;
        padding-left: 0;
        padding-right: 0;
        padding-bottom: 0; }
        .main-header__item-dropdown a {
          color: white; } }
    .main-header__item-dropdown a {
      white-space: nowrap;
      display: flex;
      align-items: center;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      line-height: 1.2;
      padding: 16px 0;
      cursor: pointer;
      border-bottom: 1px solid #d4d4d4; }
      .main-header__item-dropdown a:last-child {
        border: 0; }
  .main-header__international {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-left: 1px solid #d4d4d4;
    font-weight: 500;
    font-size: 15px;
    white-space: nowrap;
    padding: 0 20px; }
    .main-header__international-icon {
      width: 18px;
      height: 18px;
      margin-right: 12px; }
    @media only screen and (max-width: 992px) {
      .main-header__international {
        display: none; } }
  .main-header__mobile-menu {
    display: none;
    align-items: center;
    padding: 0 16px;
    position: relative; }
    .main-header__mobile-menu strong {
      margin-right: 8px; }
    .main-header__mobile-menu .opened {
      display: none; }
    @media only screen and (max-width: 992px) {
      .main-header__mobile-menu {
        display: flex; } }
  .main-header__mobile-menu-icon {
    background-color: #373a43;
    padding: 6px;
    width: 32px;
    height: 32px; }
    .main-header__mobile-menu-icon svg {
      width: 100%;
      height: 100%;
      fill: white; }

.main-footer {
  padding-top: 100px;
  background-image: url("/assets/bna-pattern.svg");
  background-position: 50% 0;
  background-size: 2500px; }
  @media only screen and (max-width: 768px) {
    .main-footer {
      padding-top: 50px; } }
  .main-footer__wrapper {
    display: flex;
    flex-direction: column; }
  .main-footer__section-container {
    display: flex; }
    @media only screen and (max-width: 768px) {
      .main-footer__section-container {
        flex-direction: column; } }
  .main-footer__section {
    flex: 1;
    color: white; }
    @media only screen and (max-width: 768px) {
      .main-footer__section {
        margin-bottom: 30px; } }
    .main-footer__section h3 {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: white; }
      .main-footer__section h3:before {
        content: "";
        display: block;
        width: 26px;
        height: 4px;
        margin-top: -5px;
        margin-right: 12px;
        background-color: #fff402; }
    .main-footer__section ul {
      list-style: none;
      padding: 0; }
      .main-footer__section ul li {
        padding: 8px 0; }
        .main-footer__section ul li a {
          color: white;
          text-decoration: underline; }
  .main-footer__privacy-bar {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    margin-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    color: white;
    font-size: 12px;
    font-weight: 500; }
    @media only screen and (max-width: 768px) {
      .main-footer__privacy-bar {
        font-size: 16px;
        margin-top: 30px;
        padding: 20px 0;
        flex-direction: column;
        justify-content: flex-start; } }
  .main-footer__bottom-menu ul {
    list-style: none;
    margin-right: 8px; }
    .main-footer__bottom-menu ul li {
      display: inline-block;
      margin-left: 18px; }
      .main-footer__bottom-menu ul li a {
        color: #969696; }
        .main-footer__bottom-menu ul li a:hover {
          text-decoration: underline; }
  @media only screen and (max-width: 768px) {
    .main-footer__bottom-menu ul {
      padding: 0; }
      .main-footer__bottom-menu ul li {
        display: block;
        font-size: 16px;
        margin: 12px 0 0 0; } }

.hero {
  height: calc(100vh - 100px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: grey;
  overflow: hidden; }
  @media only screen and (max-width: 992px) {
    .hero .breadcrumbs {
      display: none; } }
  @media only screen and (max-width: 768px) {
    .hero {
      height: auto;
      min-height: 300px; } }
  .hero--shorten {
    height: auto;
    min-height: 600px; }
    @media only screen and (max-width: 768px) {
      .hero--shorten {
        min-height: 300px; } }
  .hero.no-breadcrumbs .hero__photo-description {
    bottom: 8px; }
  .hero__image {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    top: 0;
    bottom: -25px;
    background-size: cover;
    background-position: 50% 0%;
    backface-visibility: hidden; }
    .hero__image:after {
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: linear-gradient(to bottom, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.25) 1%, rgba(0, 0, 0, 0.25) 81%, rgba(0, 0, 0, 0.5) 100%); }
  .hero__architect {
    position: absolute;
    background-color: white;
    letter-spacing: 0.5px;
    color: black;
    font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    font-weight: 600;
    right: 20px;
    top: 50px;
    font-size: 11px;
    text-transform: uppercase;
    padding: 3px 10px; }
    @media only screen and (max-width: 768px) {
      .hero__architect {
        top: 36px; } }
  .hero__photo-description {
    position: absolute;
    right: 20px;
    bottom: -30px;
    color: white;
    z-index: 10;
    font-size: 14px;
    font-style: italic; }
    @media only screen and (max-width: 992px) {
      .hero__photo-description {
        display: none; } }
  .hero__content {
    color: white;
    position: relative;
    z-index: 10;
    width: 100%;
    margin-top: 125px;
    flex-grow: 1;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media only screen and (max-width: 768px) {
      .hero__content {
        padding-top: 36px;
        padding-bottom: 36px;
        margin-bottom: 0; } }
    .hero__content h1 {
      max-width: 620px;
      margin-top: 0.7em;
      color: white; }
      @media only screen and (max-width: 768px) {
        .hero__content h1 {
          margin-top: 0.4em; } }
    .hero__content-body {
      margin-top: 20px;
      margin-bottom: 12px; }
      @media only screen and (max-width: 768px) {
        .hero__content-body {
          display: none; } }
    .hero__content h2 {
      font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 1px;
      font-size: 12px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      width: 100%;
      color: white; }
      .hero__content h2:before {
        position: relative;
        top: -1px;
        content: "";
        display: block;
        flex: 0 0 32px;
        height: 4px;
        background-color: #fff402;
        margin-right: 12px; }
    .hero__content-cta {
      display: flex;
      color: white;
      font-size: 18px;
      margin-top: 30px; }
      .hero__content-cta img {
        width: 50px;
        margin-right: 12px; }
  .hero__tabs {
    position: absolute;
    display: flex;
    color: black;
    right: calc((100% - 1140px) / 2);
    bottom: 0;
    z-index: 3; }
    @media all and (max-width: 1200px) {
      .hero__tabs {
        right: 30px; } }
    @media only screen and (max-width: 768px) {
      .hero__tabs {
        position: relative;
        right: auto;
        top: auto;
        flex-direction: column;
        width: 100%;
        padding: 0 20px; } }
    .hero__tabs-single {
      text-align: center;
      min-width: 180px;
      background-color: #fff402;
      box-shadow: inset 0 -5px 8px -6px black;
      padding: 10px 20px 8px;
      font-weight: 500;
      width: 120px;
      margin-left: 20px;
      cursor: pointer; }
      .hero__tabs-single.active {
        background-color: white;
        box-shadow: none;
        cursor: default; }
      @media only screen and (max-width: 768px) {
        .hero__tabs-single {
          width: 100%;
          margin-left: 0;
          margin-bottom: 12px;
          padding-top: 15px;
          padding-bottom: 15px;
          box-shadow: none; } }
  .hero__project-nav {
    position: absolute;
    bottom: 0;
    z-index: 20;
    background-color: white;
    height: 60px;
    font-size: 13px;
    padding: 6px 12px;
    line-height: 1.1em;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    font-weight: 500; }
    @media only screen and (max-width: 768px) {
      .hero__project-nav {
        background-color: transparent;
        color: white; }
        .hero__project-nav svg {
          fill: white; } }
    .hero__project-nav svg {
      width: 23px; }
    .hero__project-nav--prev {
      left: 0;
      align-items: flex-start; }
    .hero__project-nav--next {
      right: 0;
      text-align: right; }

[data-hero-tab-content|="2"] {
  display: none; }

.hero-simple {
  background-image: url("/assets/bna-pattern.svg");
  background-position: 50% 0;
  background-size: 2500px; }
  @media only screen and (max-width: 992px) {
    .hero-simple .breadcrumbs {
      display: none; } }
  .hero-simple__content {
    padding-top: 185px;
    padding-bottom: 36px; }
    @media only screen and (max-width: 768px) {
      .hero-simple__content {
        padding-top: 120px; } }
  .hero-simple h1 {
    font-size: 36px;
    color: white; }
    .hero-simple h1 strong,
    .hero-simple h1 span {
      font-weight: inherit;
      color: #fff402; }
    @media only screen and (max-width: 768px) {
      .hero-simple h1 {
        font-size: 28px; } }
    .hero-simple h1 + em {
      font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 1px;
      font-size: 11px;
      text-transform: uppercase;
      color: #969696;
      letter-spacing: 0.4px;
      display: block;
      margin-top: 1em; }

.usps {
  background-color: #f6f6f6;
  position: relative; }
  @media only screen and (max-width: 768px) {
    .usps {
      display: none; } }
  .usps.top-space:before {
    content: "";
    position: absolute;
    display: block;
    height: 80px;
    bottom: 100%;
    left: 0;
    right: 0;
    background-color: #f6f6f6; }
  .usps__entries {
    padding-top: 23px;
    padding-bottom: 23px;
    margin: 0 auto;
    display: flex;
    list-style: none;
    justify-content: space-between; }
    @media only screen and (max-width: 768px) {
      .usps__entries {
        flex-direction: column;
        justify-content: initial;
        align-items: flex-start; } }
    .usps__entries li {
      display: flex;
      align-items: center;
      justify-content: center; }
      @media only screen and (max-width: 768px) {
        .usps__entries li {
          padding-bottom: 12px; } }
  .usps__icon {
    width: 40px;
    height: 40px;
    margin-right: 16px; }

.global-brochure {
  background-color: #f6f6f6;
  padding: 70px 0; }
  @media only screen and (max-width: 768px) {
    .global-brochure {
      padding: 0; } }
  .global-brochure__download {
    padding: 110px 60px;
    background-color: white;
    position: relative; }
    @media only screen and (max-width: 768px) {
      .global-brochure__download {
        background-color: transparent;
        padding: 40px 20px; } }
    .global-brochure__download-icon {
      display: block;
      position: absolute;
      left: -12px;
      top: -12px;
      width: 92px;
      height: 92px;
      padding: 20px;
      background-color: #fff402; }
      @media only screen and (max-width: 768px) {
        .global-brochure__download-icon {
          display: none; } }
    .global-brochure__download:before {
      content: "";
      left: 250px;
      top: -70px;
      bottom: 0;
      right: auto;
      width: calc((100% - 250px) + ((100vw - 100%) / 2));
      position: absolute;
      display: block;
      background: white;
      z-index: 1; }
      @media only screen and (max-width: 768px) {
        .global-brochure__download:before {
          display: none; } }
  .global-brochure h2 {
    font-size: 36px;
    margin-bottom: 26px; }
    @media only screen and (max-width: 768px) {
      .global-brochure h2 {
        font-size: 28px;
        line-height: 0.93; } }
  .global-brochure__body {
    display: flex;
    align-items: flex-start; }
  .global-brochure__copy {
    margin-top: 12px; }
  .global-brochure__icon-check {
    width: 50px;
    min-width: 40px;
    height: 50px;
    margin-right: 20px; }
  .global-brochure__link {
    position: absolute;
    z-index: 3;
    bottom: -20px;
    left: -10px; }
    @media only screen and (max-width: 768px) {
      .global-brochure__link {
        position: static;
        margin-top: 30px;
        width: 100%; }
        .global-brochure__link span {
          flex-grow: 1; } }
  .global-brochure__content {
    position: relative;
    z-index: 2; }

.share {
  border-top: 1px solid #c0c0c0;
  margin-top: 40px;
  padding-top: 40px; }
  .share__header {
    display: block;
    margin-bottom: 12px; }
  .share__icon {
    width: 50px;
    height: 50px;
    margin-right: 10px;
    margin-top: 6px; }

.custom-input-block__wrapper {
  display: flex;
  align-items: stretch;
  position: relative;
  z-index: 2;
  padding-top: 60px;
  padding-bottom: 0;
  margin-top: 0;
  margin-bottom: 0; }
  .custom-input-block__wrapper:before {
    content: "";
    display: block;
    background-color: white;
    position: absolute;
    right: 15%;
    top: 0;
    left: calc(((100vw - 100%) / -2));
    bottom: 0;
    z-index: -1; }
  @media only screen and (max-width: 768px) {
    .custom-input-block__wrapper {
      flex-direction: column;
      background-color: white;
      padding-top: 0; }
      .custom-input-block__wrapper:before {
        display: none; } }

.custom-input-block__left {
  width: 45%;
  padding-right: 30px;
  padding-top: 60px;
  padding-bottom: 80px; }
  @media only screen and (max-width: 768px) {
    .custom-input-block__left {
      width: 100%;
      padding-top: 0; } }

.custom-input-block__right {
  width: 55%;
  background-size: cover; }
  @media only screen and (max-width: 768px) {
    .custom-input-block__right {
      display: none; } }

.custom-input-block__subheader {
  display: flex;
  align-items: center;
  font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-size: 12px;
  margin-bottom: 36px;
  margin-top: 30px;
  letter-spacing: 1.2px; }
  .custom-input-block__subheader:before {
    content: "";
    display: block;
    left: 0;
    width: 36px;
    height: 4px;
    background-color: #fff402;
    margin-right: 12px; }

.custom-input-block__header {
  font-style: normal;
  font-size: 38px;
  line-height: 1;
  margin-bottom: 30px; }
  @media only screen and (max-width: 768px) {
    .custom-input-block__header {
      font-size: 28px; } }

.custom-input-block__body {
  margin-bottom: 20px; }

.entry-blocks {
  background-color: white;
  padding: 60px 0 40px; }
  @media only screen and (max-width: 768px) {
    .entry-blocks {
      padding-top: 20px; } }

.breadcrumbs {
  position: relative;
  z-index: 3;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.6);
  width: 100%;
  height: 60px;
  padding: 16px 0; }

.breadcrumb-list {
  list-style: none;
  display: flex;
  align-items: center;
  padding: 0; }
  .breadcrumb-list li {
    font-size: 13px; }
    .breadcrumb-list li a {
      color: white; }
      .breadcrumb-list li a:hover {
        text-decoration: underline; }
    .breadcrumb-list li:last-child {
      color: #fff402;
      text-decoration: underline; }
      .breadcrumb-list li:last-child a {
        color: #fff402; }

.breadcrumb-arrow {
  display: block;
  margin: 0 10px; }

.breadcrumbs-mobile {
  position: relative;
  z-index: 3;
  border-top: 1px solid #c0c0c0;
  border-bottom: 1px solid #c0c0c0;
  width: 100%;
  padding: 16px 0;
  display: none;
  margin-top: 30px; }
  @media only screen and (max-width: 992px) {
    .breadcrumbs-mobile {
      display: block; } }
  .breadcrumbs-mobile__list {
    list-style: none;
    align-items: center;
    padding: 0; }
    .breadcrumbs-mobile__list li {
      font-size: 15px;
      line-height: 2.08;
      display: inline-block; }
      .breadcrumbs-mobile__list li a:hover {
        text-decoration: underline; }
      .breadcrumbs-mobile__list li:last-child {
        color: #2c6d7f;
        text-decoration: underline; }
        .breadcrumbs-mobile__list li:last-child a {
          color: #2c6d7f; }
  .breadcrumbs-mobile__arrow {
    margin: 0 10px;
    display: inline-block; }

.contact-banner {
  display: flex;
  width: 100%;
  margin-top: 50px;
  align-items: stretch; }
  @media only screen and (max-width: 992px) {
    .contact-banner {
      flex-direction: column; } }
  .contact-banner__left {
    background-color: #fff402;
    flex-grow: 1;
    padding: 45px 37px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    width: 100%; }
    .contact-banner__left:after {
      content: "";
      display: block;
      position: absolute;
      right: -13px;
      top: 47px;
      width: 0;
      height: 0;
      border-left: 13px solid #fff402;
      border-top: 18px solid transparent;
      border-bottom: 18px solid transparent; }
    @media only screen and (max-width: 992px) {
      .contact-banner__left {
        padding: 20px 18px; }
        .contact-banner__left:after {
          left: 50%;
          right: auto;
          transform: translateX(-50%);
          top: auto;
          bottom: -13px;
          border-bottom: 0;
          border-top: 13px solid #fff402;
          border-left: 18px solid transparent;
          border-right: 18px solid transparent; } }
    .contact-banner__left-copy {
      margin-bottom: 30px; }
    .contact-banner__left h3 {
      font-size: 19px;
      margin-bottom: 0.5em; }
  .contact-banner__right {
    min-width: 310px;
    min-height: 300px;
    padding: 18px 28px;
    display: flex;
    align-items: flex-end;
    background-size: cover;
    background-color: #969696; }
    @media only screen and (max-width: 768px) {
      .contact-banner__right {
        min-width: 0; } }
  .contact-banner__employee {
    color: white;
    font-size: 13px; }
    .contact-banner__employee strong {
      display: block; }

.contact-banner-small {
  background-color: #fff402;
  padding: 20px;
  margin-bottom: 30px; }
  .contact-banner-small h3 {
    border-bottom: 1px solid black;
    font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 22px; }
  .contact-banner-small__agency, .contact-banner-small__employee {
    font-size: 13px;
    color: black; }
  .contact-banner-small strong {
    display: block;
    font-weight: 500;
    line-height: 1.1em;
    letter-spacing: -0.2px; }
  .contact-banner-small .btn {
    margin-top: 24px; }

.latest-projects {
  background-color: #f6f6f6; }
  .latest-projects__wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 70px 40px;
    display: flex;
    flex-direction: column;
    position: relative; }
    .latest-projects__wrapper:after {
      content: "";
      left: 100%;
      top: -120px;
      bottom: 0;
      width: calc((100vw - 100%) / 2);
      position: absolute;
      display: block;
      background: white;
      z-index: 1; }
    @media only screen and (max-width: 768px) {
      .latest-projects__wrapper {
        padding-left: 0;
        padding-right: 0; }
        .latest-projects__wrapper:after {
          display: none; } }
    .latest-projects__wrapper h3 {
      margin-bottom: 0.7em; }
  .latest-projects__single {
    display: flex;
    padding: 30px 20px;
    align-items: flex-start;
    border-top: 1px solid #c0c0c0; }
    @media only screen and (max-width: 768px) {
      .latest-projects__single {
        padding-left: 0; } }
    .latest-projects__single-img {
      height: 100px;
      width: 160px;
      margin-right: 20px;
      background-color: #c0c0c0;
      overflow: hidden; }
      .latest-projects__single-img img {
        width: 100%; }
      @media only screen and (max-width: 768px) {
        .latest-projects__single-img {
          display: none; } }
    .latest-projects__single-content h4 {
      font-size: 19px;
      font-weight: 500;
      margin-bottom: 6px; }
    .latest-projects__single-architects {
      font-size: 13px; }
      .latest-projects__single-architects strong {
        font-weight: 500; }

.country-tracker {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 74px;
  background-color: #fff402;
  z-index: 100;
  transition: 300ms ease-in-out transform;
  box-shadow: 0 -14px 34px 0 rgba(0, 0, 0, 0.3); }
  @media only screen and (max-width: 992px) {
    .country-tracker {
      height: auto;
      padding: 20px; } }
  .country-tracker.closed {
    transition: 250ms ease-in-out transform;
    transform: translateY(200%); }
  .country-tracker__container {
    height: 100%;
    align-items: center;
    justify-content: space-between; }
    @media only screen and (max-width: 992px) {
      .country-tracker__container {
        padding-left: 0;
        padding-right: 30px; } }
  .country-tracker__body strong {
    font-weight: 500; }
  .country-tracker__body a {
    color: #2c6d7f;
    text-decoration: underline;
    margin-left: 10px; }
    @media only screen and (max-width: 992px) {
      .country-tracker__body a {
        display: block;
        margin: 0; } }
  .country-tracker__close {
    font-weight: 600;
    font-size: 26px;
    padding: 0 12px;
    cursor: pointer; }
    @media only screen and (max-width: 992px) {
      .country-tracker__close {
        position: absolute;
        right: 10px;
        top: 10px;
        font-size: 32px; } }
  .country-tracker__ornament {
    position: relative;
    top: -18px; }
    @media only screen and (max-width: 992px) {
      .country-tracker__ornament {
        display: none; } }

.news-filter__categories {
  display: flex;
  flex-wrap: wrap; }

.news-filter__categories-single {
  cursor: pointer;
  padding: 8px;
  color: #000;
  background: #f6f6f6;
  border: 1px solid #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
  font-weight: 500;
  font-style: normal;
  letter-spacing: 1px;
  font-size: 12px;
  font-weight: 400;
  padding: 3px 7px;
  letter-spacing: 1px;
  z-index: 1;
  margin-bottom: 10px;
  text-transform: uppercase;
  margin-right: 8px; }
  .news-filter__categories-single.active {
    color: #000;
    background: #fff402;
    font-weight: 500;
    border: 1px solid #fff402; }

.news-filter__articles {
  display: flex;
  flex-wrap: wrap;
  max-width: 900px;
  margin: 36px auto 0;
  margin-bottom: 142px; }
  @media only screen and (max-width: 768px) {
    .news-filter__articles {
      flex-direction: column;
      margin-bottom: 50px; } }

.news-filter .article-single {
  width: calc(50% - 18px);
  position: relative;
  display: block;
  margin-top: 36px; }
  @media only screen and (max-width: 768px) {
    .news-filter .article-single {
      width: 100%; } }
  .news-filter .article-single:nth-of-type(odd) {
    margin-right: 18px; }
  .news-filter .article-single:nth-of-type(even) {
    margin-left: 18px;
    top: 100px; }
  @media only screen and (max-width: 768px) {
    .news-filter .article-single:nth-of-type(1n) {
      top: auto;
      margin: 0 0 24px 0; } }
  .news-filter .article-single__category-label {
    position: absolute;
    left: 0;
    top: 0;
    display: flex; }
    .news-filter .article-single__category-label span {
      font-size: 11px;
      font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
      font-weight: 400;
      font-style: normal;
      letter-spacing: 1px;
      line-height: 1.1;
      letter-spacing: 1px;
      color: #000;
      background: #fff402;
      text-transform: uppercase;
      padding: 4px 7px;
      z-index: 1;
      margin-bottom: 8px; }
      .news-filter .article-single__category-label span:not(:last-child) {
        margin-right: 8px; }
  .news-filter .article-single__img {
    width: 100%;
    height: 56vw;
    display: block;
    max-height: 512px;
    background-color: grey;
    background-size: cover;
    background-position: 50% 50%; }
  .news-filter .article-single__meta {
    padding: 34px 28px 0 28px;
    height: 150px;
    background-color: #f2f2f2; }
  .news-filter .article-single__title {
    display: block;
    color: black;
    font-size: 19px;
    font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 500;
    font-style: normal; }
  .news-filter .article-single__date {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-family: "Roboto Mono", Monaco, "Courier New", Courier, monospace;
    font-weight: 400;
    font-style: normal;
    letter-spacing: 1px;
    line-height: 1.1;
    letter-spacing: 0.5px;
    margin-top: 1.5em;
    text-transform: uppercase; }
    .news-filter .article-single__date:before {
      content: "";
      position: relative;
      display: block;
      width: 30px;
      height: 6px;
      margin-right: 10px;
      background-color: #fff200; }
  .news-filter .article-single__no-results {
    width: 100%;
    display: block;
    text-align: center;
    padding: 80px 0 40px; }

.news-filter__loadmore-container {
  max-width: 900px;
  margin: 0 auto; }

.news-filter__loadmore {
  display: flex;
  max-width: 260px;
  height: 64px;
  background-color: #373a43;
  color: white;
  padding: 20px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-weight: 500; }
  .news-filter__loadmore-spinner {
    width: 30px;
    height: 30px;
    margin-left: 20px; }

.news-filter__load-widget {
  position: relative;
  min-height: 300px; }
  .news-filter__load-widget .news-filter__load-widget-spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); }

.modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000; }
  .modal__background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.85);
    z-index: 5; }
  .modal__close {
    position: absolute;
    top: 40px;
    font-size: 50px;
    line-height: 22px;
    right: 50px;
    z-index: 10;
    text-align: center;
    padding: 6px 6px 6px 6px;
    color: white;
    cursor: pointer; }
    @media only screen and (max-width: 768px) {
      .modal__close {
        right: 26px; } }
  .modal__view {
    position: absolute;
    left: 50%;
    top: 50%;
    width: auto;
    min-width: 200px;
    min-height: 120px;
    background-color: white;
    z-index: 10;
    transform: translate(-50%, -50%);
    opacity: 0;
    animation: animate-modal 0.4s forwards;
    animation-delay: 0.3s; }

@keyframes animate-modal {
  0% {
    transform: translate(-50%, -60%);
    opacity: 0; }
  100% {
    transform: translate(-50%, -50%);
    opacity: 1; } }
  .modal__static {
    padding: 40px 32px 30px 32px; }
    .modal__static p {
      text-align: center;
      color: black; }

.image-gallery {
  width: 100%;
  background-color: black; }
  .image-gallery__wrapper {
    display: flex;
    padding-top: 30px;
    padding-bottom: 30px;
    margin-top: 0;
    margin-bottom: 0; }
    @media only screen and (max-width: 768px) {
      .image-gallery__wrapper {
        flex-direction: column; } }
  .image-gallery__big-img-container {
    width: 47.5%; }
    .image-gallery__big-img-container .image-gallery__img {
      margin: 0 18px 0 0;
      width: calc(100% - 30px);
      height: calc((14.5vw * 2) + 30px);
      max-height: calc(360px + 30px); }
      @media only screen and (max-width: 768px) {
        .image-gallery__big-img-container .image-gallery__img {
          width: 100%;
          margin: 0 0 30px 0;
          height: 80vw;
          max-height: 400px; } }
    @media only screen and (max-width: 768px) {
      .image-gallery__big-img-container {
        width: 100%; } }
  .image-gallery__small-img-container {
    display: flex;
    flex-wrap: wrap;
    width: 52.5%; }
    @media only screen and (max-width: 768px) {
      .image-gallery__small-img-container {
        width: 100%; } }
    .image-gallery__small-img-container .image-gallery__img {
      width: calc(33.33% - (30px - (30px / 3)));
      height: 14.5vw;
      max-height: 180px;
      margin-bottom: 30px; }
      .image-gallery__small-img-container .image-gallery__img:nth-of-type(3n + 1) {
        margin-right: calc(30px - (30px / 3)); }
      .image-gallery__small-img-container .image-gallery__img:nth-of-type(3n + 2) {
        margin-right: calc(30px / 3);
        margin-left: calc(30px / 3); }
      .image-gallery__small-img-container .image-gallery__img:nth-of-type(3n + 3) {
        margin-left: calc(30px - (30px / 3)); }
      .image-gallery__small-img-container .image-gallery__img:nth-of-type(1n + 4) {
        margin-bottom: 0; }
      @media only screen and (max-width: 768px) {
        .image-gallery__small-img-container .image-gallery__img {
          width: calc(50% - (30px - (30px / 2)));
          height: 40vw;
          max-height: 280px; }
          .image-gallery__small-img-container .image-gallery__img:nth-of-type(odd) {
            margin-right: calc(30px - (30px / 2));
            margin-left: 0; }
          .image-gallery__small-img-container .image-gallery__img:nth-of-type(even) {
            margin-left: calc(30px - (30px / 2));
            margin-right: 0; }
          .image-gallery__small-img-container .image-gallery__img:nth-last-child(2), .image-gallery__small-img-container .image-gallery__img:nth-last-child(1) {
            margin-bottom: 0; } }
  .image-gallery__img {
    display: block;
    cursor: pointer;
    position: relative;
    background-size: cover;
    background-color: grey; }
    .image-gallery__img.stack span {
      position: absolute;
      z-index: 2;
      color: white;
      font-weight: 700;
      left: 50%;
      top: 50%;
      font-size: 38px;
      transform: translate(-50%, -50%); }
    .image-gallery__img.stack:after {
      content: "";
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      display: block;
      background-color: rgba(21, 106, 132, 0.9); }

.gallery-detail {
  box-sizing: border-box;
  position: relative; }
  .gallery-detail__img {
    display: block;
    max-height: 70vh;
    max-width: 1024px; }
    @media only screen and (max-width: 768px) {
      .gallery-detail__img {
        width: calc(100vw - 40px); } }
  .gallery-detail__previous, .gallery-detail__next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: #fff402;
    width: 60px;
    height: 60px;
    padding: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; }
  .gallery-detail__previous {
    left: calc((100vw - 100%) / -2); }
  .gallery-detail__next {
    right: calc((100vw - 100%) / -2); }
  .gallery-detail__info {
    text-align: center;
    position: absolute;
    bottom: calc(((100vh - 100%) / -2) + 26px);
    width: 100%;
    color: white; }
  .gallery-detail__source {
    display: block;
    font-size: 14px; }
    .gallery-detail__source strong {
      color: #fff402;
      font-weight: 400;
      text-decoration: underline; }
  .gallery-detail__bullets {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 20px; }
    .gallery-detail__bullets .gallery-detail__bullet {
      width: 18px;
      height: 3px;
      background-color: white;
      margin-right: 12px;
      cursor: pointer; }
      .gallery-detail__bullets .gallery-detail__bullet:last-child {
        margin: 0; }
      .gallery-detail__bullets .gallery-detail__bullet.active {
        background-color: #fff402; }

.video-bar {
  padding: 12px 12px 12px 30px;
  background-color: #fff402;
  max-width: 1160px;
  margin: 0 auto;
  line-height: 1;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -36px;
  position: relative;
  z-index: 20; }
  @media only screen and (max-width: 992px) {
    .video-bar {
      margin-top: 0; } }
  @media only screen and (max-width: 768px) {
    .video-bar {
      flex-direction: column;
      padding: 12px; } }
  .video-bar h2 {
    margin-right: 12px;
    font-size: 22px; }
    @media only screen and (max-width: 768px) {
      .video-bar h2 {
        margin: 0;
        text-align: center; } }
  .video-bar__video {
    min-width: 120px;
    width: 120px;
    height: 66px;
    background-color: black;
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center; }
    .video-bar__video video {
      width: 100%; }
    @media only screen and (max-width: 768px) {
      .video-bar__video {
        margin-top: 12px;
        height: auto;
        width: 100%; } }
  .video-bar__icon {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 30px;
    transform: translate(-50%, -50%); }

.project-single__body {
  width: calc(32% - 26px); }

.project-single__specifications {
  background-color: #f6f6f6;
  padding: 20px;
  margin-bottom: 30px; }
  .project-single__specifications #toggle_specification_list {
    display: none;
    transition: 300ms ease-in-out; }
  .project-single__specifications.type-collapse {
    position: relative;
    overflow: hidden;
    transition: 300ms ease-in-out;
    padding-bottom: 40px; }
    .project-single__specifications.type-collapse #toggle_specification_list {
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 90px;
      background: red;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 60px 20px 14px 20px;
      font-size: 13px;
      background: linear-gradient(to bottom, rgba(246, 246, 246, 0) 0%, #f6f6f6 50%, #f6f6f6 100%); }
      .project-single__specifications.type-collapse #toggle_specification_list:before {
        content: "";
        left: 0;
        right: 0;
        background: #c0c0c0;
        height: 1px;
        position: absolute;
        top: 42px; }
      .project-single__specifications.type-collapse #toggle_specification_list:after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        border: 2px solid #c0c0c0;
        border-left: 0;
        border-bottom: 0;
        transform: translateY(-5px) rotate(135deg); }
    .project-single__specifications.type-collapse.collapsed #toggle_specification_list:after {
      transform: translateY(0) rotate(-45deg); }
  .project-single__specifications h3 {
    border-bottom: 1px solid #c0c0c0;
    font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 22px; }
  .project-single__specifications strong,
  .project-single__specifications span {
    font-size: 13px; }
  .project-single__specifications strong {
    display: block;
    font-weight: 500;
    line-height: 1.1em;
    letter-spacing: -0.2px; }
  .project-single__specifications ul {
    list-style: none;
    padding: 0; }
    .project-single__specifications ul li {
      margin-top: 19px;
      letter-spacing: -0.2px; }

.project-single__credits {
  border-bottom: 1px solid #c0c0c0;
  margin-bottom: 32px; }
  .project-single__credits strong,
  .project-single__credits span {
    font-size: 13px; }
  .project-single__credits strong {
    display: block;
    font-weight: 500;
    line-height: 1.1em;
    letter-spacing: -0.2px; }
  .project-single__credits-entry {
    margin-bottom: 20px; }

.app-page.homepage {
  background-image: url("/assets/bna-pattern.svg");
  background-position: 0 100%;
  background-size: 170% auto;
  background-color: black; }
  .app-page.homepage .main-footer {
    background: none; }

* {
  margin: 0; }

body {
  font-family: "Graphik", "Helvetica Neue", Helvetica, Roboto, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  line-height: 1.6;
  background-color: white;
  color: #373a43;
  box-sizing: border-box; }
  body * {
    box-sizing: inherit;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: #373a43; }

h1,
h2,
h3 {
  color: #000; }
  h1.theme-header:before,
  h2.theme-header:before,
  h3.theme-header:before {
    content: "";
    display: inline-block;
    width: 4px;
    height: calc(1em - 9px);
    margin-right: 20px;
    background-color: #fff402; }

h1 {
  font-size: 48px;
  font-family: "Serifa", Times, "Times New Roman", serif;
  font-weight: 500;
  font-style: normal;
  line-height: 1.1em; }
  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 28px; } }

h2 {
  font-family: "Serifa", Times, "Times New Roman", serif;
  font-weight: 500;
  font-style: normal;
  font-size: 36px; }
  h2.theme-header {
    font-size: 36px; }
    @media only screen and (max-width: 768px) {
      h2.theme-header {
        font-size: 22px; } }
  @media only screen and (max-width: 768px) {
    h2 {
      font-size: 22px; } }

h3 {
  font-size: 22px;
  font-family: "Serifa", Times, "Times New Roman", serif;
  font-weight: 500;
  font-style: normal; }

input,
textarea {
  outline: none;
  padding: 0 16px;
  font-size: 14px; }

b,
strong {
  font-weight: 700; }

i,
em {
  font-style: italic; }

::placeholder {
  font-weight: normal;
  color: #969696; }

a,
.button {
  cursor: pointer; }

.redactor h1,
.redactor h2,
.redactor h3 {
  margin-bottom: 0.5em; }

.redactor p {
  margin-bottom: 1em;
  font-size: 17px; }

.redactor blockquote {
  font-family: "Serifa", Times, "Times New Roman", serif;
  font-weight: 500;
  font-style: normal;
  position: relative;
  min-height: 82px;
  height: auto;
  display: block;
  overflow: hidden;
  position: relative;
  line-height: 1.2;
  color: black;
  text-align: center;
  font-size: 20px;
  margin-top: 38px;
  margin-bottom: 14px;
  padding: 0 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap; }
  .redactor blockquote em {
    font-style: normal;
    font-size: 26px; }
  @media only screen and (max-width: 768px) {
    .redactor blockquote {
      padding: 0 32px; }
      .redactor blockquote em {
        font-size: 22px; } }
  .redactor blockquote:before {
    top: -50px;
    left: 0;
    height: 140px;
    width: 105px;
    overflow: hidden;
    font-family: "Serifa", Times, "Times New Roman", serif;
    font-weight: 500;
    font-style: normal;
    font-weight: 500;
    font-style: normal;
    display: block;
    position: absolute;
    font-size: 250px;
    line-height: unset;
    z-index: -199;
    color: #fff200;
    content: open-quote; }
