.thumbnail-blocks {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 40px;

  &.cap {
    .thumbnail-blocks__entry {
      &:nth-of-type(1n + 7) {
        display: none;
      }
    }
  }

  &__show-more {
    width: 100%;
    display: flex;
    justify-content: center;
    border-top: 1px solid grey;
    margin-top: 30px;
  }

  &__entry {
    display: flex;
    flex-direction: column;
    width: calc(33.33% - 20px);
    margin-bottom: 30px;

    &:hover {
      .thumbnail-blocks__img-el {
        transform: scale(1.05);
        transition: 250ms ease-in-out;
      }
    }

    &:nth-of-type(3n + 1) {
      margin-right: 20px;
    }
    &:nth-of-type(3n + 2) {
      margin-left: 10px;
      margin-right: 10px;
    }
    &:nth-of-type(3n + 3) {
      margin-left: 20px;
    }

    @include mobile {
      width: 100%;
      &:nth-of-type(1n) {
        margin: {
          left: 0;
          right: 0;
        }
      }
    }

    h3 {
      @include font-graphik-regular;
      font-weight: 500;
      font-size: 17px;
      color: black;
    }
    p {
      font-size: 13px;
      strong {
        font-weight: 500;
      }
    }
  }

  &__label {
    @include bna-label();
    position: absolute;
    left: 0;
    top: 0;
    padding: 6px 12px;
    z-index: 3;
  }

  &__img {
    height: 22vw;
    max-height: 260px;
    background: $color-white-smoke;
    width: 100%;
    position: relative;
    overflow: hidden;
    @include mobile {
      height: 50vw;
      max-height: 360px;
    }
    &-el {
      transition: 250ms ease-in-out;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: {
        size: cover;
      }
    }
  }
  &__description {
    flex-grow: 1;
    width: 100%;
    background-color: #edf5f7;
    padding: 22px 20px;
  }
}
