// BTN BASE STYLING

.btn {
  display: inline-flex;
  color: white;
  background-color: black;
  padding: 22px 30px;
  font-size: 16px;
  font-weight: 500;

  &.stretch {
    width: 100%;
    span {
      text-align: center;
      flex-grow: 1;
      font-size: inherit;
    }
  }
  &--download {
    background: transparent;
    color: black;
    padding-left: 0;
  }

  &__arrow-bottom {
    height: 40px;
    width: 46px;
    margin-right: 14px;
  }

  &__arrow-right {
    margin-left: 12px;
    height: 20px;
    width: 26px;
    transition: 300ms ease-in-out;
  }
  &:hover {
    .btn__arrow-right {
      transform: translateX(6px);
      transition: 300ms ease-in-out;
    }
  }
}
