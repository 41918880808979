// POSSIBLE COLLORS
$color-golden-yellow: #ffec00;
$color-yellow: #fff402;
$color-lemon-yellow: #f8e71c;
$color-blizzard-blue: #a3e8f4;
$color-atoll-green: #2c6d7f;
$color-mountain-meadow: #1ae37c;

$color-white-smoke: #f6f6f6;
$color-shady-lady-grey: #969696;
$color-vulcan-grey: #373a43;
$color-nero-grey: #222222;
$color-storm-dust-grey: #61615f;
$color-hint-of-red-grey: #fdfbfb;
$color-concrete: #f2f2f2;
$color-silver: #c0c0c0;

$color-almost-white: $color-hint-of-red-grey;
$color-xlight-grey: $color-white-smoke;
$color-light-grey: #d4d4d4;
$color-medium-grey: $color-shady-lady-grey;
$color-dark-grey: $color-storm-dust-grey;
$color-xdark-grey: $color-vulcan-grey;
$color-almost-black: $color-nero-grey;

$color-bna-yellow: $color-yellow;
$color-bna-yellow-shadow: $color-lemon-yellow;
$color-light-turquoise: $color-blizzard-blue;
$color-xdark-turquoise: $color-atoll-green;
$color-light-green: $color-mountain-meadow;

$color-red: #ff0802;

$text-color: #373a43;

$color-blue-grey: #eaf3f6;
$color-white-grey: #f1f1f1;
$color-white: #ffffff;
$color-black: #000;
$color-blue-smoke: #edf5f7;
