$gutter: 30px;

.image-gallery {
  width: 100%;
  background-color: black;
  &__wrapper {
    display: flex;
    padding: {
      top: 30px;
      bottom: 30px;
    }
    margin: {
      top: 0;
      bottom: 0;
    }

    @include mobile {
      flex-direction: column;
    }
  }

  // gutter definition

  &__big-img-container {
    width: 47.5%;
    .image-gallery__img {
      margin: 0 18px 0 0;
      width: calc(100% - #{$gutter});
      height: calc((14.5vw * 2) + #{$gutter});
      max-height: calc(360px + #{$gutter});
      @include mobile {
        width: 100%;
        margin: 0 0 $gutter 0;
        height: 80vw;
        max-height: 400px;
      }
    }
    @include mobile {
      width: 100%;
    }
  }
  &__small-img-container {
    display: flex;
    flex-wrap: wrap;
    width: 52.5%;
    @include mobile {
      width: 100%;
    }
    .image-gallery__img {
      width: calc(33.33% - (#{$gutter} - (#{$gutter} / 3)));
      height: 14.5vw;
      max-height: 180px;
      margin-bottom: $gutter;

      &:nth-of-type(3n + 1) {
        margin-right: calc(#{$gutter} - (#{$gutter} / 3));
      }
      &:nth-of-type(3n + 2) {
        margin-right: calc(#{$gutter} / 3);
        margin-left: calc(#{$gutter} / 3);
      }
      &:nth-of-type(3n + 3) {
        margin-left: calc(#{$gutter} - (#{$gutter} / 3));
      }
      &:nth-of-type(1n + 4) {
        margin-bottom: 0;
      }

      @include mobile {
        width: calc(50% - (#{$gutter} - (#{$gutter} / 2)));
        height: 40vw;
        max-height: 280px;

        &:nth-of-type(odd) {
          margin-right: calc(#{$gutter} - (#{$gutter} / 2));
          margin-left: 0;
        }
        &:nth-of-type(even) {
          margin-left: calc(#{$gutter} - (#{$gutter} / 2));
          margin-right: 0;
        }
        &:nth-last-child(2),
        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
  &__img {
    display: block;
    cursor: pointer;
    position: relative;
    background: {
      size: cover;
      color: grey;
    }
    &.stack {
      span {
        position: absolute;
        z-index: 2;
        color: white;
        font-weight: 700;
        left: 50%;
        top: 50%;
        font-size: 38px;
        transform: translate(-50%, -50%);
      }
      &:after {
        content: "";
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        position: absolute;
        display: block;
        background-color: rgba(21, 106, 132, 0.9);
      }
    }
  }
}
