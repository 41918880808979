.latest-projects {
  background-color: $color-white-smoke;

  &__wrapper {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 70px 40px;
    display: flex;
    flex-direction: column;
    position: relative;
    &:after {
      content: "";
      left: 100%;
      top: -120px;
      bottom: 0;
      width: calc((100vw - 100%) / 2);
      position: absolute;
      display: block;
      background: white;
      z-index: 1;
    }
    @include mobile {
      padding: {
        left: 0;
        right: 0;
      }
      &:after {
        display: none;
      }
    }
    h3 {
      margin-bottom: 0.7em;
    }
  }

  &__single {
    display: flex;
    padding: 30px 20px;
    align-items: flex-start;
    border-top: 1px solid $color-silver;
    @include mobile {
      padding: {
        left: 0;
      }
    }

    &-img {
      height: 100px;
      width: 160px;
      margin-right: 20px;
      background-color: $color-silver;
      overflow: hidden;
      img {
        width: 100%;
      }
      @include mobile {
        display: none;
      }
    }

    &-content {
      h4 {
        font-size: 19px;
        font-weight: 500;
        margin-bottom: 6px;
      }
    }
    &-architects {
      font-size: 13px;
      strong {
        font-weight: 500;
      }
    }
  }
}
