.global-brochure {
  background-color: $color-white-smoke;
  padding: 70px 0;
  @include mobile {
    padding: 0;
  }
  &__download {
    padding: 110px 60px;
    background-color: white;
    position: relative;
    $brochure-offset-left: 250px;
    @include mobile {
      background-color: transparent;
      padding: 40px 20px;
    }

    &-icon {
      display: block;
      position: absolute;
      left: -12px;
      top: -12px;
      width: 92px;
      height: 92px;
      padding: 20px;
      background-color: $color-bna-yellow;

      @include mobile {
        display: none;
      }
    }

    &:before {
      content: "";
      left: $brochure-offset-left;
      top: -70px;
      bottom: 0;
      right: auto;
      width: calc((100% - #{$brochure-offset-left}) + ((100vw - 100%) / 2));
      position: absolute;
      display: block;
      background: white;
      z-index: 1;

      @include mobile {
        display: none;
      }
    }
  }
  h2 {
    font-size: 36px;
    margin-bottom: 26px;

    @include mobile {
      font-size: 28px;
      line-height: 0.93;
    }
  }
  &__body {
    display: flex;
    align-items: flex-start;
  }
  &__copy {
    margin-top: 12px;
  }
  &__icon-check {
    width: 50px;
    min-width: 40px;
    height: 50px;
    margin-right: 20px;
  }
  &__link {
    position: absolute;
    z-index: 3;
    bottom: -20px;
    left: -10px;
    @include mobile {
      position: static;
      margin-top: 30px;
      width: 100%;
      span {
        flex-grow: 1;
      }
    }
  }
  &__content {
    position: relative;
    z-index: 2;
  }
}
