.main-footer {
  padding-top: 100px;
  background: {
    image: url("/assets/bna-pattern.svg");
    position: 50% 0;
    size: 2500px;
  }
  @include mobile {
    padding-top: 50px;
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
  }
  &__section-container {
    display: flex;
    @include mobile {
      flex-direction: column;
    }
  }
  &__section {
    flex: 1;
    color: white;
    @include mobile {
      margin-bottom: 30px;
    }
    h3 {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      color: white;

      &:before {
        content: "";
        display: block;
        width: 26px;
        height: 4px;
        margin-top: -5px;
        margin-right: 12px;
        background-color: $color-bna-yellow;
      }
    }
    ul {
      list-style: none;
      padding: 0;
      li {
        padding: 8px 0;
        a {
          color: white;
          text-decoration: underline;
        }
      }
    }
  }
  &__privacy-bar {
    display: flex;
    justify-content: space-between;
    padding: 40px 0;
    margin-top: 60px;
    border-top: 1px solid rgba(255, 255, 255, 0.7);
    color: white;
    font-size: 12px;
    font-weight: 500;

    @include mobile {
      font-size: 16px;
      margin-top: 30px;
      padding: 20px 0;
      flex-direction: column;
      justify-content: flex-start;
    }
  }
  &__bottom-menu {
    ul {
      list-style: none;
      margin-right: 8px;
      li {
        display: inline-block;
        margin-left: 18px;
        a {
          color: $color-shady-lady-grey;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
    @include mobile {
      ul {
        padding: 0;
        li {
          display: block;
          font-size: 16px;
          margin: 12px 0 0 0;
        }
      }
    }
  }
}
