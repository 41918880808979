@mixin basic-reset {
  list-style: none;
  background: none;
  text-decoration: none;
  border: none;
  margin: 0;
  padding: 0;
}

@mixin limit-lines($amount) {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $amount;
  -webkit-box-orient: vertical;
}

@mixin bna-label {
  font-size: 11px;
  @include font-roboto-mono-regular;
  line-height: 1.1;
  letter-spacing: 1px;
  color: $color-black;
  background: $color-bna-yellow;
  text-transform: uppercase;
}

@mixin bna-prefix {
  &:before {
    content: "";
    display: block;
    left: 0;
    width: 36px;
    height: 4px;
    background-color: $color-bna-yellow;
    margin-right: 12px;
  }
}

@mixin date-format {
  display: flex;
  align-items: center;
  font-size: 12px;
  @include font-roboto-mono-regular;
  line-height: 1.1;
  letter-spacing: 0.5px;
  margin-top: 1.5em;
  text-transform: uppercase;

  &:before {
    content: "";
    position: relative;
    display: block;
    width: 30px;
    height: 6px;
    margin-right: 10px;
    background-color: #fff200;
  }
}
