// DIVICE SIZES
$tablet: "768px";
$desktop: "992px";
$desktop-big: "1800px";

// BASIC QUERIES
@mixin mobile {
  @media only screen and  (max-width: #{$tablet}) {
    @content;
  }
}
@mixin tablet {
  @media only screen and  (max-width: #{$desktop}) {
    @content;
  }
}
@mixin desktop {
  @media only screen and (min-width: #{$desktop}) {
    @content;
  }
}
